@import "themes/default";

$primary-color: $default-primary-color;
$primary-rgba: $default-primary-rgba;
$hover-color: $default-hover-color;
$secondary-color: #ababab;
$secondary-hover-color: #999;

// lending tree overrides
$lendingtree-primary-color: #08c177;
$lendingtree-primary-rgba: rgba(8, 193, 119, 0.99);
$lendingtree-hover-color: #0dda88;

// kwipped overrides
$kwipped-primary-color: #418ac9;
$kwipped-primary-rgba: rgba(65, 138, 201, 0.99);
$kwipped-hover-color: #59a0c9;

$background-color: #ffffff;
$dark-text-color: #2c3842;
$med-text-color: #545454;
$med-light-text-color: #888888;
$light-text-color: #aaaaaa;
$white-text-color: #ffffff;

$error-color: #ed4337;
// $error-color: #f44336;
// $error-color: #ffae42;

$footer-text-color: #2c3842;
$footer-bg-color: #e7e7e7;

$border-color: #ababab;
$med-border-color: #cccccc;
$light-border-color: #dedede;

:export {
	primary: $primary-color;
	primaryRgba: $primary-rgba;
	hover: $hover-color;
	secondary: $secondary-color;
	secondaryHover: $secondary-hover-color;
	lendingTreePrimary: $lendingtree-primary-color;
	lendingTreeRgba: $lendingtree-primary-rgba;
	lendingTreeHover: $lendingtree-hover-color;
	kwippedPrimary: $kwipped-primary-color;
	kwippedRgba: $kwipped-primary-rgba;
	kwippedHover: $kwipped-hover-color;
}
