/* react-step-progress-bar */
.RSPBprogression {
  height: 3px;
}

/* modal styles */
.mui-modal p,
.mui-modal h6 {
  margin-bottom: 10px;
  line-height: 1.2em;
}
.modal-close-icon {
  position: absolute;
  top: -35px;
  right: 0;
}
/* end modal styles */
