@import "typography";
@import "colors";
@import "mixins";

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
header,
main,
footer {
  flex-shrink: 0 !important;
}
main {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  flex-basis: auto;
}
.app-section {
  margin: 0 auto;
  padding: 30px;
}
.app-section form,
.app-section .form-width {
  max-width: 300px;
  margin: 0 auto;
}
.app-section .docusign {
  width: 300px;
  margin: 0 auto;
}
.app-section h2 {
  @include t1;
  color: $primary-color;
  font-weight: 500;
  margin: 10px 0 30px;
}
.app-section h3 {
  @include t2;
  color: $primary-color;
  font-weight: 500;
  margin: 10px 0 30px;
}
.app-section h4 {
  @include s1;
  color: $primary-color;
  margin: 0 0 15px;
}
.app-section h5 {
  @include t3;
  color: $med-text-color;
  font-weight: 500;
}

/* animation for swiping questions */
.slide {
  position: absolute;
  width: 100%;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-enter.slide-enter-active {
  transform: translateX(0);
  transition: transform 250ms ease-in;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 250ms ease-in;
}
/* end animations style */

.alert-loader {
  margin-left: 5px;
}
.alert-message > div:first-child {
  width: 80%;
}
