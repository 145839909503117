@import "typography";
@import "colors";
@import "mixins";

button:focus,
input:focus {
	outline: none !important;
	outline-color: transparent;
	outline-style: none;
}
/* disable input shadows on ios safari */
input[type="text"],
input[type="tel"],
input[type="text"],
input[type="number"],
input[type="email"] {
	appearance: none;
}
/* disable the spinner on number input */
input[type="number"] {
	-moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
/* end - disable the spinner on number input */

/* main button styles */
.btn {
	@include buttonPrimary;
	background-color: $primary-color !important;
	&:hover {
		background-color: $hover-color !important;
	}
	&:active {
		background-color: $hover-color !important;
	}
}
.btn-secondary {
	@include buttonSecondary;
	background-color: $secondary-color !important;
	&:hover {
		background-color: $secondary-hover-color !important;
	}
	&:active {
		background-color: $secondary-hover-color !important;
	}
}
/* end main button styles */

/* input and label style */
.app-input {
	margin: 15px auto;
}
.app-input label {
	@include s1;
	color: $med-light-text-color;
	font-weight: 400;
	display: block;
	margin: 20px 0 5px;
}
.app-input input {
	@include t3;
	color: $dark-text-color;
	border: solid 1px $med-border-color;
	line-height: 2rem;
	margin: 0 auto;
	display: block;
	text-align: center;
	width: 100%;
	background: #fff;
	border-radius: 1px;
	padding: 5px;
}
.app-input input:focus {
	border-color: $primary-color;
}
.wrap-input {
	width: 80%;
	position: relative;
	margin: 0 auto;
}
.wrap-input .error-text {
	top: 3px;
}

.label-above {
	@include s1;
	color: $med-light-text-color;
	font-weight: 400;
	display: block;
	margin: 20px 0 5px;
}

/* validation css */
.invalid input {
	border-color: $error-color !important;
}
.error-text {
	color: $error-color !important;
	position: absolute;
	font-size: 10px;
	top: 6px;
	left: 10px;
}
/* end - validation css */

/* start custom material-ui overrides */
div.inline {
	display: inline;
	position: relative;
}
.mui-input-custom {
	margin: 0;
}
.mui-input-custom > div:before,
.mui-input-custom > div:after,
.mui-date-picker > div:before,
.mui-date-picker > div:after {
	border: none !important;
}
.mui-input-custom input:focus {
	border-color: $primary-color;
}
.mui-select div.Mui-focused {
	border: solid 1px $primary-color !important;
}
.mui-input-custom > div,
.mui-input-custom > div:before,
.mui-input-custom > div:after,
.mui-date-picker > div,
.mui-date-picker > div:before,
.mui-date-picker > div:after {
	border-bottom: none;
}
.mui-input-custom input,
.mui-input-custom div.MuiSelect-select {
	border: solid 1px $med-border-color;
	border-radius: 1px;
	padding: 20px 0 7px 10px;
	font-weight: 300;
}
.mui-input-custom label[data-shrink="true"] {
	position: absolute;
	top: 20px;
	left: 10px;
	font-size: 13px;
}
.mui-input-custom label[data-shrink="false"] {
	position: absolute;
	top: 10px;
	left: 10px;
	text-transform: unset;
}
.mui-input-custom.invalid label[data-shrink="true"] {
	display: none;
}
.mui-select {
	top: 16px;
}
.mui-select label[data-shrink="true"] {
	position: absolute;
	top: -3px;
	left: 0;
	font-size: 13px;
	color: #777;
}
.input-100 {
	width: 100%;
}
.input-50 {
	width: 49%;
}
.input-50.mr {
	margin-right: 2%;
}

.mui-date-picker input {
	@include t3;
	text-align: left;
	padding: 8px 0 8px 60px;
	width: 130px;
}
.mui-date-picker {
	border: solid 1px $med-border-color !important;
	border-radius: 1px;
	width: 230px;
	margin-bottom: 15px !important;
}
.invalid .mui-date-picker:focus-within,
.mui-date-picker:focus-within {
	border: solid 1px $primary-color !important;
}
.mui-date-picker p {
	color: $error-color;
	position: absolute;
	top: 40px;
	text-align: center;
	width: 100%;
}
.invalid .mui-date-picker {
	border-color: $error-color !important;
}
/* end custom material ui overrides */

input[type="checkbox"] {
	margin-right: 12px;
}
input[type="checkbox"]:focus {
	outline: 3px solid #2c77f6 !important;
}

/* new payment selection radio buttons */
fieldset {
	font-family: "Roboto";
	text-align: center;
	border: none;
}
.term-radio {
	display: inline-block;
	margin: 5px;
}
.term-radio input[type="radio"] {
	display: none;
}
.term-radio input[type="radio"] + label {
	display: block;
	height: 60px;
	width: 60px;
	padding: 12px;
	border-radius: 30px;
	box-sizing: border-box;
	font-size: 30px;
	line-height: 37px;
	font-weight: bold;
	text-align: center;
	color: #ffffff;
	border: none;
	background: #9f9f9f;
	position: relative;
	cursor: pointer;
}
.term-radio input[type="radio"]:checked + label,
.term-radio input[type="radio"] + label:hover {
	background: $primary-color;
}
/* end new payment selection radio buttons */

/* eq grid layout for terms */
.term-radio-grid-item {
	width: 50%;
	float: left;
	padding: 5px;
	box-sizing: border-box;
}

.term-radio-grid-item input[type="radio"] {
	display: none;
}
.term-radio-grid-item input[type="radio"] + label {
	display: block;
	box-sizing: border-box;
	font-size: 30px;
	line-height: 80px;
	font-weight: bold;
	text-align: center;
	color: #777;
	border: none;
	background: #ededed;
	position: relative;
	cursor: pointer;
}
.est-payment {
	background: #ededed;
}
.term-radio-grid-item input[type="radio"]:checked + label,
.term-radio-grid-item input[type="radio"] + label:hover,
.term-radio-grid-item input[type="radio"]:checked + label + .est-payment,
.term-radio-grid-item input[type="radio"] + label:hover + .est-payment {
	background: $primary-color;
	color: #fff;
}
/* end eq grid layout */

/* custom radio button/box styles PAYMENT SELECTION */
.radio-box {
	float: left;
	margin: 0 auto 3%;
}
.radio-box input[type="radio"] {
	display: none;
}
.radio-box input[type="radio"]:checked + label {
	box-shadow: 0 1px 6px $primary-color;
	background: #eee;
}
.radio-box label {
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	width: 100%;
	cursor: pointer;
	display: block;
}
.radio-box-header {
	color: $white-text-color;
	background: $primary-color;
	line-height: 20px;
	font-weight: 500;
	padding: 8px 0 5px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	text-transform: uppercase;
}
.terms-only .radio-box-header {
	color: $med-text-color;
	background: $white-text-color;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.radio-box-body {
	@include t2;
	color: $dark-text-color;
	line-height: 1.3em;
	padding: 15px 10px;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.rb-100 {
	width: 100%;
}
.rb-50 {
	width: 49%;
}
.rb-50:nth-child(odd) {
	margin-right: 2%;
}
.rb-100 .radio-box-body {
	@include s0;
	padding: 25px 10px;
}
.rb-50 .radio-box-body {
	color: $primary-color;
	line-height: 1em;
}
/* end custom radio button/box styles */

/* custom radio button/box styles CHOICE BUTTONS */
.radio-button {
	float: left;
	margin: 0 auto 3%;
}
.radio-button input[type="radio"] {
	display: none;
}
.radio-button label {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
	font-weight: lighter;
	padding: 12px;
	margin: 5px;
	width: 120px !important;
	color: $white-text-color;
	background: $primary-color;
	border-radius: 5px;
	width: 100%;
	cursor: pointer;
	display: block;
}
.loan-type-wrap {
	width: 260px;
	margin: 0 auto;
}
/* end custom radio button/box styles */

/* range input styles */
input[type="range"] {
	appearance: none;
	margin: 18px 0;
	width: 100%;
}
input[type="range"]:focus {
	outline: none;
}
input[type="range"]::-moz-focus-outer {
	border: 0;
}
@mixin track() {
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: #eee;
	background-color: #eee;
}
@mixin thumb() {
	height: 30px;
	width: 30px;
	border-radius: 30px;
	background: $primary-color;
	cursor: pointer;
	appearance: none;
	margin-top: -15px;
}
input[type="range"] {
	&::-webkit-slider-runnable-track {
		@include track;
	}
	&::-moz-range-track {
		@include track;
	}
	&::-ms-track {
		@include track;
	}
	&::-webkit-slider-thumb {
		@include thumb;
	}
	&::-moz-range-thumb {
		@include thumb;
	}
	&::-ms-thumb {
		@include thumb;
	}
}
/* end of range input sytles */
