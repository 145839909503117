@import "../colors";

.lendingtree {
	/* base.scss */
	.faux-link,
	a:link,
	a:visited,
	a:hover {
		color: $lendingtree-primary-color;
	}

	/* layouts.scss */
	.app-section h2,
	.app-section h3,
	.app-section h4 {
		color: $lendingtree-primary-color;
	}

	/* Content.scss */
	.signIn {
		color: $lendingtree-primary-color;
	}

	/* Business.scss */
	.naics-radio input[type="radio"]:checked + label .resultItem,
	.structure-radio input[type="radio"]:checked + label,
	.structure-radio input[type="radio"] + label:hover {
		background: $lendingtree-primary-color !important;
	}
	.structure-radio input[type="radio"] + label {
		border: solid 1px $lendingtree-primary-color;
	}

	/* forms.scss */
	.btn {
		@include buttonPrimary;
		background-color: $lendingtree-primary-color !important;
		&:hover {
			background-color: $lendingtree-hover-color !important;
		}
		&:active {
			background-color: $lendingtree-hover-color !important;
		}
	}
	.app-input input:focus {
		border-color: $lendingtree-primary-color;
	}
	.mui-input-custom input:focus {
		border-color: $lendingtree-primary-color;
	}
	.mui-date-picker:focus-within {
		border: solid 1px $lendingtree-primary-color !important;
	}
	.radio-box input[type="radio"]:checked + label {
		box-shadow: 0px 1px 6px $lendingtree-primary-color;
	}
	.radio-box-header {
		background: $lendingtree-primary-color;
	}
	.term-radio-grid-item input[type="radio"]:checked + label,
	.term-radio-grid-item input[type="radio"] + label:hover,
	.term-radio-grid-item input[type="radio"]:checked + label + .est-payment,
	.term-radio-grid-item input[type="radio"] + label:hover + .est-payment {
		background: $lendingtree-primary-color;
	}
	input[type="range"] {
		&::-webkit-slider-thumb {
			background: $lendingtree-primary-color;
		}
		&::-moz-range-thumb {
			background: $lendingtree-primary-color;
		}
		&::-ms-thumb {
			background: $lendingtree-primary-color;
		}
	}

	/* User.scss */
	.content h3 {
		color: $lendingtree-primary-color;
	}
	.profile h5 {
		color: $lendingtree-primary-color;
	}
	.app-info-box {
		border: solid 1px $lendingtree-primary-color;
	}
	.app-info-box .title {
		color: $lendingtree-primary-color;
	}

	/* Address.scss */
	.searchInput:focus-within {
		border-color: $lendingtree-primary-color;
	}

	/* Modals.scss */
	a > button.btn-logout {
		color: $lendingtree-primary-color !important;
	}

	/* Error.scss */
	.error-boundary .crack {
		polyline {
			stroke: $lendingtree-primary-color;
		}
	}
	@keyframes pulseColor {
		0% {
			color: $lendingtree-primary-color;
		}
		50% {
			color: $lendingtree-primary-color;
		}
		100% {
			color: $lendingtree-primary-color;
		}
	}
	.error-boundary .main a {
		&:hover,
		&:focus {
			b {
				color: $lendingtree-primary-color;
			}
		}
		&:active {
			b {
				color: $lendingtree-primary-color;
			}
		}
	}
}

/* Modals.scss */
.done-modal.lendingtree {
	margin-top: 25px !important;
}
