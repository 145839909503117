@import "colors";

@mixin buttonPrimary {
  color: #fff !important;
  padding: 12px !important;
  border-radius: 5px;
  text-transform: none !important;
  cursor: pointer;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;

  &:disabled {
    background-color: #c3c3c3 !important;
    color: #fff !important;
  }
}

@mixin buttonSecondary {
  @include buttonPrimary();
}
