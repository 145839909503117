@mixin t1 {
  font-size: 1.9rem;
}

@mixin t2 {
  font-size: 1.5rem;
}

@mixin t3 {
  font-size: 1.3rem;
}

@mixin s0 {
  font-size: 1rem;
}

@mixin s1 {
  font-size: 0.9rem;
}

@mixin s2 {
  font-size: 0.8rem;
}

.t1 {
  @include t1;
}

.t2 {
  @include t2;
}

.t3 {
  @include t3;
}

.s1 {
  @include s1;
}

.s2 {
  @include s2;
}
