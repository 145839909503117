@import "../../../../scss/typography";
@import "../../../../scss/colors";

.error-boundary {
  flex-grow: 1;
  background: url("error-background.png");
  margin-top: 5px;
}
.error-boundary:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}
/* sentry.io error report modal */
.sentry-error-embed-wrapper {
  padding: 80px 20px 10px !important;
}
.sentry-error-embed {
  margin-top: 0 !important;
}
.eb-back-link-wrap {
  margin-top: 25px;
  position: relative;
  z-index: 10;
}
.error-page-credit {
  font-size: 10px;
  color: #999;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.btn {
  border: none !important; // this overrides crash report submit button
}

.error-boundary .main a {
  display: block;
  cursor: pointer;
  animation: pulseColor 1000ms linear 3100ms forwards;
  font-weight: 500;
  &:hover,
  &:focus {
    b {
      color: $primary-color;
    }
  }
  &:active {
    b {
      color: $primary-color;
    }
  }
}

.error-boundary .main svg {
  width: 50px;
  height: auto;
}

.error-boundary .crack {
  position: relative;
  z-index: 4;
  margin-left: -50px;
  polyline {
    fill: none;
    stroke: $primary-color;
    stroke-width: 10px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 1649.099;
    stroke-dashoffset: 1649.099;
    animation: drawStroke 1500ms ease-out 500ms forwards;
  }
}

.error-boundary .main {
  @include t2;
  color: $med-text-color;
  padding-top: 10%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    svg {
      position: relative;
      z-index: 1;
      polygon {
        fill: transparent;
      }
    }
    span {
      display: block;
      position: relative;
      z-index: 0;
      padding: 0 12px;
      line-height: 1.4;
    }
    &:first-child {
      text-align: right;
      z-index: 1;
      span {
        &:first-child {
          opacity: 0;
          transform: translateX(100%);
          animation: translateLeft 1000ms linear 1250ms forwards;
        }
        &:last-child {
          opacity: 0;
          transform: translateX(100%);
          animation: translateLeft 1000ms linear 1450ms forwards;
        }
      }
      svg {
        polygon {
          animation: removeFill 10ms ease-out 1600ms forwards;
        }
      }
    }
    &:last-child {
      z-index: 0;
      margin-left: -50px;
      span {
        &:first-child {
          opacity: 0;
          transform: translateX(-100%);
          animation: translateRight 1000ms linear 1650ms forwards;
        }
        &:last-child {
          opacity: 0;
          transform: translateX(-100%);
          animation: translateRight 1000ms linear 1850ms forwards;
        }
      }
    }
  }
}

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 1649.099;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes removeFill {
  0% {
    fill: rgba($background-color, 1);
  }
  100% {
    fill: rgba($background-color, 0);
  }
}

@keyframes pulseColor {
  0% {
    color: $primary-color;
  }
  25% {
    color: $light-text-color;
  }
  50% {
    color: $primary-color;
  }
  75% {
    color: $light-text-color;
  }
  100% {
    color: $primary-color;
  }
}

/* https://goo.gl/v323yz */
@keyframes translateLeft {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

/* https://goo.gl/p1Hnde */
@keyframes translateRight {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
