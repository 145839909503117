@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700");
@import "typography";
@import "colors";

html {
  box-sizing: border-box;
  width: 100%;
}
body {
  min-width: 375px;
  font-family: Roboto, Tahoma, Verdana, sans-serif;
  font-size: 16px;
  overscroll-behavior: none;
  text-align: center;
}
html,
body {
  color: $med-light-text-color;
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.faux-link,
a:link,
a:visited,
a:hover {
  color: $primary-color;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.inverse {
  .faux-link,
  a:link,
  a:visited,
  a:hover {
    color: #fff !important;
  }
}
.left {
  text-align: left;
}
.show {
  display: inherit;
}
.hide {
  display: none !important;
}

.muted {
  color: $light-text-color !important;
  font-weight: lighter;
}
.faux-link.muted {
  font-weight: bold;
}

ul.left-list-inverse {
  text-align: left;
  color: #fff;
}